exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-horizontes-js": () => import("./../../../src/pages/horizontes.js" /* webpackChunkName: "component---src-pages-horizontes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-junquillal-js": () => import("./../../../src/pages/junquillal.js" /* webpackChunkName: "component---src-pages-junquillal-js" */),
  "component---src-pages-marino-js": () => import("./../../../src/pages/marino.js" /* webpackChunkName: "component---src-pages-marino-js" */),
  "component---src-pages-murcielago-js": () => import("./../../../src/pages/murcielago.js" /* webpackChunkName: "component---src-pages-murcielago-js" */),
  "component---src-pages-naranjo-js": () => import("./../../../src/pages/naranjo.js" /* webpackChunkName: "component---src-pages-naranjo-js" */),
  "component---src-pages-naranjo-test-js": () => import("./../../../src/pages/naranjo-test.js" /* webpackChunkName: "component---src-pages-naranjo-test-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-reservacion-js": () => import("./../../../src/pages/reservacion.js" /* webpackChunkName: "component---src-pages-reservacion-js" */),
  "component---src-pages-santa-rosa-js": () => import("./../../../src/pages/santa-rosa.js" /* webpackChunkName: "component---src-pages-santa-rosa-js" */),
  "component---src-pages-sr-escuelas-js": () => import("./../../../src/pages/sr-escuelas.js" /* webpackChunkName: "component---src-pages-sr-escuelas-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

